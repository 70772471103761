.circular-div {
  width: 36px;
  height: 36px;
  border-radius: 50%; /* Makes the div circular */
  background-color: #ffc107;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
