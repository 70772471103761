.btn-linkedin {
  background: #0e76a8;
  border-radius: 8px;
  color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #084461;
  padding: 5px;
}
.btn-linkedin:link,
.btn-linkedin:visited {
  color: #fff;
}
.btn-linkedin:active,
.btn-linkedin:hover {
  background: #084461;
  color: #fff;
}
