tr.expandable > td {
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.2);
  padding: 0;
}

tr.expandable > td > .inner {
  margin: 15px;
  overflow: hidden;
}

.title {
  color: #e14eca;
  font-weight: 400;
  margin-bottom: 15px;
}
